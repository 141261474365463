.App {
  background-color: #282c34;
  min-height: 100vh;
  padding-top: 10vh;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.App-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
}

.construction-message {
  color: white;
  text-align: center;
}

.socialListing {
  text-align: center;
}

.social {
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 20px;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

ul {
  list-style-type: none;
}

.socialContainer {
  display: flex;
  flex: 0.8;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
